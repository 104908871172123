import React, { useEffect, useState } from "react"
import Layout from "../../components/Community/Layout"
import { Link } from "@reach/router"
import { client, urlFor } from "../../sanity"
import {
  Flex,
  Title,
  Text,
  Box,
  Label,
  Image,
  Grid,
} from "../../components/system"
import Loading from "../../components/Loading"
import Stage from "../../components/Community/stage"

const AssignedPerson = props => {
  const [profile, setProfile] = useState(null)
  const [stages, setStages] = useState()

  useEffect(() => {
    const query =
      '*[_type == "user" && _id == $id]{"stages": *[ _type == "stage" && owner._ref == ^._id ] | order(_createdAt asc),_id, name,location,link,bio,avatar{asset->{url}}}'
    const params = { id: props.location.state.id }

    client.fetch(query, params).then(data => {
      const person = data[0]
      setProfile(person)
      // console.log(person.stages[1])
      const stageNumber = {
        one: {
          submitted: person.stages[0] !== undefined ? true : false,
          approved: false,
          id: person.stages[0]?._id,
        },
        two: {
          submitted: person.stages[1] !== undefined ? true : false,
          approved: false,
          id: person.stages[1]?._id,
        },
        three: {
          submitted: person.stages[2] !== undefined ? true : false,
          approved: false,
          id: person.stages[2]?._id,
        },
        four: {
          submitted: person.stages[3] !== undefined ? true : false,
          approved: false,
          id: person.stages[3]?._id,
        },
      }
      setStages(stageNumber)
    })
  }, [props])

  if (!profile) {
    return <Loading />
  }

  return (
    <Layout>
      <Flex flexDirection="column" alignItems="center" mt={6} px={[3, 5]}>
        <Flex justifyContent="flex-start" alignItems="flex-start">
          <Title
            fontWeight="bold"
            fontSize={[6, 7]}
            textAlign="left"
            color="primary"
          >
            {profile.name}
          </Title>
        </Flex>
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection={["column", "row"]}
        >
          <Box width={["100%", "50%"]}>
            <Image
              src={urlFor(profile.avatar)
                .width(500)
                .height(500)
                .quality(90)
                .url()}
            />
          </Box>

          <Flex flexDirection="column" pl={[0, 5]}>
            <Flex flexDirection="column" alignItems="flex-start" mb={4}>
              <Label color="secondary">Bio</Label>
              <Text textAlign="left" pr={[0, 6]}>
                {profile.bio
                  ? profile.bio
                  : "This user hasn't added their bio yet"}
              </Text>
            </Flex>
            <Flex justifyContent="flex-start">
              {profile.location && (
                <Flex flexDirection="column" alignItems="flex-start">
                  <Label color="secondary">Based</Label>
                  <Text textAlign="left" pr={[0, 6]}>
                    {profile.location}
                  </Text>
                </Flex>
              )}
              {profile.link && (
                <Flex flexDirection="column" alignItems="flex-start">
                  <Label color="secondary">Link</Label>
                  <Text textAlign="left" pr={[0, 6]}>
                    {profile.link}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        {stages && (
          <Grid
            gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"]}
            gridGap={4}
            my={[3, 6]}
          >
            <Link to={`/community/view-stage/${stages?.one.id}`}>
              <Stage
                number="1"
                title="Fashion Sketch & CAD Design"
                visible={stages?.one.submitted}
                submitted={stages?.one.submitted}
              />
            </Link>
            <Link to={`/community/view-stage/${stages?.two.id}`}>
              <Stage
                number="2"
                title="Toile"
                visible={stages?.two.submitted}
                submitted={stages?.two.submitted}
              />
            </Link>
            <Link to={`/community/view-stage/${stages?.three.id}`}>
              <Stage
                number="3"
                title="Sample"
                visible={stages?.three.submitted}
                submitted={stages?.three.submitted}
              />
            </Link>
            <Link to={`/community/view-stage/${stages?.four.id}`}>
              <Stage
                number="4"
                title="Collection"
                visible={stages?.four.submitted}
                submitted={stages?.four.submitted}
              />
            </Link>
          </Grid>
        )}
      </Flex>
    </Layout>
  )
}
export default AssignedPerson
