import React from "react"
import { Flex, Title, Text } from "../system"
import { Tick } from "../Icons"

const Stage = ({ number, title, visible, submitted, approved }) => (
  <Flex
    flexDirection="column"
    alignItems="flex-start"
    justifyContent="space-between"
    width={["90vw", "40vw", "20vw"]}
    height={["90vw", "40vw", "20vw"]}
    border={visible ? "2px solid black" : "2px solid #CECECE"}
    borderRadius={5}
    p={3}
  >
    <Flex flexDirection="column" alignItems="flex-start">
      <Title mb={1} color={visible ? "black" : "faded"}>
        {number}.
      </Title>
      <Text textAlign="left" color={visible ? "black" : "faded"}>
        {title}
      </Text>
    </Flex>
    <Flex justifyContent="space-between" alignItems="center">
      <Text
        textAlign="left"
        mb={0}
        color={visible ? (submitted ? "black" : "secondary") : "faded"}
      >
        {visible
          ? submitted
            ? approved
              ? "Approved"
              : "Submitted"
            : "Start Now"
          : "Locked"}
      </Text>
      <Flex
        bg={visible ? (submitted ? "black" : "secondary") : "faded"}
        width={40}
        height={40}
        minWidth={40}
        borderRadius={20}
      >
        {submitted ? (
          <Tick color="white" />
        ) : (
          <Text mb={0} color="white">
            ->
          </Text>
        )}
      </Flex>
    </Flex>
  </Flex>
)

export default Stage
